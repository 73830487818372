import React from 'react';
import { PageProps, graphql } from 'gatsby';
import * as Sentry from '@sentry/gatsby';
import { Helmet } from 'react-helmet';
import Cookie from 'js-cookie';
import { GetHubungiWaDataQuery } from '../graphql-types';
import 'twin.macro';
import { css } from '@emotion/react';
import BaseLayout from '../layouts/base';
import { getCookies } from '../utils/getcookies';
import { utmType } from '../types/utmType';

export interface LeadsData {
  gaClientId: string;
  analyticsData: utmType;
}

export interface LeadsResponse {
  ticketId: number;
  phoneNumber: string;
}

export interface HubungiWaState {
  isSendWAFailed: boolean;
}

class HubungiWa extends React.Component<PageProps<GetHubungiWaDataQuery>, HubungiWaState> {
  gaAttempt: number;

  constructor(props: PageProps) {
    super(props);
    this.gaAttempt = 0;
    this.state = { isSendWAFailed: false };
  }

  /**
   * build whatsapp link from phone number and message template
   * e.g. createWALink('6212091', '#{ticket} selamat datang {name}', { ticket: 1, name: 'budi' })
   */
  createWALink(phone: string, template: string, params: { [key: string]: string }): string {
    let message = template;
    for (let key of Object.keys(params)) {
      const pattern = new RegExp(`\\{${key}\\}`, 'g');
      message = message.replace(pattern, params[key]);
    }
    return `https://wa.me/${phone}?text=${message}`;
  }

  getGAClientID(): string {
    if (typeof window !== undefined) {
      return Cookie.get('_ga');
    }
    return null;
  }

  getMessageTemplate(): string {
    if (typeof window !== undefined) {
      const params = new URLSearchParams(window.location.search);
      if (params.has('message')) {
        return params.get('message');
      }
      return null;
    }
    return null;
  }

  async reportLeads(leads: LeadsData): Promise<LeadsResponse> {
    if (typeof window !== undefined) {
      const url = `${process.env.NETLIFY_FUNCTION_BASE_URL}/.netlify/functions/report-leads`;
      const res = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leads),
      });
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const data = await res.json();
      return data as LeadsResponse;
    }
  }

  getAnalyticsData = (): utmType => {
    const cookieValue = getCookies('utmdt');
    return JSON.parse(cookieValue);
  };

  async componentDidMount() {
    // Get source data from script
    const analyticsData = this.getAnalyticsData();

    try {
      const gaClientId = this.getGAClientID();
      const { ticketId, phoneNumber } = await this.reportLeads({
        gaClientId,
        analyticsData,
      });

      // send message
      const defaultTemplate =
        'Halo, saya ingin menghubungi Easybiz. Nomor tiket saya {ticket}, silahkan dicatat untuk sesi konsultasi';
      const template = this.getMessageTemplate() ?? defaultTemplate;
      const link = this.createWALink(phoneNumber, template, { ticket: ticketId.toString() });
      if (typeof window !== undefined) {
        window.location.href = link;
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
      this.setState({
        isSendWAFailed: true,
      });
    }
  }

  render() {
    const data = this.props.data;
    const logo = data?.logo?.childrenImageSharp[0]?.fluid;
    const isSendWAFailed = this.state.isSendWAFailed;

    const desc =
      'Hubungi kami via Whatsapp untuk seluruh kebutuhan legalitas dan administrasi legalitas seputar badan usaha, perizinan usaha, OSS RBA, LKPM, dan lain-lain';
    const title = 'Hubungi Kami Via Whatsapp - Easybiz';

    return (
      <BaseLayout logo={logo}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={desc} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={title} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={title} />
        </Helmet>
        <section
          css={css`
            min-height: 150px;
          `}
        >
          <div tw="container">
            <div tw="mx-8 lg:mx-32 mt-28 lg:mt-16">
              <div tw="mt-40 text-center">
                <h1 tw="hidden">Hubungi Kami via Whatsapp - Easybiz</h1>
                {!isSendWAFailed ? (
                  <>
                    <h2>Mohon ditunggu...</h2>
                    <div>
                      Anda sedang dihubungkan dengan salah seorang konsultan kami melalui Whatsapp
                    </div>
                  </>
                ) : (
                  <>
                    <h2 tw="text-red">Gagal menghubungi konsultan</h2>
                    <p>
                      Maaf telah terjadi kesalahan pada sistem kami, mohon refresh halaman, atau
                      dapat kembali ke <a href="/">beranda</a>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </BaseLayout>
    );
  }
}

export default HubungiWa;

export const query = graphql`
  query getHubungiWaData {
    logo: file(relativePath: { eq: "images/logo.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 130) {
          ...FileImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        ...SiteMetadataFields
      }
    }
  }
`;
